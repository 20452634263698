import React, { useState, useRef, useEffect } from "react";
import {
  processPassageAudio,
  processAudio,
  processText,
  playAudio,
  playSystemAudio,
  stopSystemAudioPlayback,
  processChatAudio,
  processAdviceAudio,
  detectLanguageWithWhisper
} from "../../../../utils/OpenAi";

import useCheckSilence from "../../../../utils/hooks/useCheckSilence";
// import { detectLanguageFromAudio } from "../../../../utils/detectLanguageFromAudio"
import { analyzeChatStreamWithLangDetection } from "../../../../utils/languageDetection/analyzeChatStreamWithLangDetection";
import { combineAudioBlobs } from "../../../../utils/combineAudioBlobs";
/**
 * Higher-Order Component (HOC) to provide recording functionality to a wrapped component.
 * @param {React.Component} WrappedComponent - The component to wrap with recording functionality.
 * @returns {React.Component} - The wrapped component with added recording functionality.
 */
const withRecording = (WrappedComponent) => {
  return (props) => {
    const wordDetectedRef = useRef(null);

    const [isRecording, setIsRecording] = useState(false);
    const [dataArray, setDataArray] = useState(null);
    const [analyser, setAnalyser] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);

    const [audioHistory, setAudioHistory] = useState([]);
    const [systemAudioBlobs, setSystemAudioBlobs] = useState([]);
    const [userAudioBlobs, setUserAudioBlobs] = useState([]);
    const [combinedBlobs, setCombinedBlobs] = useState([]);
    const [systemAndUserAudio, setSystemAndUserAudio] = useState(null);

    const [passageData, setPassageData] = useState(null);
    const [activityCountdownTimerComplete, setActivityCountdownTimerComplete] =
      useState(false);
    const [wordDetected, setWordDetected] = useState(wordDetectedRef.current);

    const initialQuestionAskedRef = useRef(false);
    const {
      silentAfterTalking4AtLeast5Seconds,
      isSilentForFiveSeconds,
      isTalkingForFiveSeconds,
      startedTalking,
      toggle,
      type,
      rms,
      reset,
    } = useCheckSilence(
      analyser,
      isRecording,
      dataArray,
      activityCountdownTimerComplete
    );
    const audioChunks = useRef([]);
    const mediaRecorder = useRef(null);

    // Define refs for system media recorder and system audio chunks
    /***********************************************************/
    const systemMediaRecorder = useRef(null); // For system audio
    const systemAudioChunks = useRef([]); // Separate audio chunks for system audio
    // const systemAudioBlobRef = useRef(null)
    /***********************************************************/
    const audioContext = useRef(null);
    const animationFrameId = useRef(null);
    const isSilentRef = useRef(isSilentForFiveSeconds);
    const textRef = useRef();

    /********************************
     * Setup microphone for recording
     * and calls process Audio
     * @returns {Promise<void>}
     *********************************/
    const startRecording = async (
      isSystemAudioResponseRequested,
      initialSystemQuestion,
      comType,
      systemPrompt,
      currentActivityType,
      chatTurnCounter,
      nextBtnClicked
    ) => {
      const chatInteraction =
        comType === "spoken" &&
        systemPrompt &&
        currentActivityType === "chat" &&
        typeof chatTurnCounter === "number" &&
        typeof initialSystemQuestion === "string";

      console.log("chatInteraction:", chatInteraction);

      if (chatInteraction) {
        // console.log(
        //   "STARTING RECORDING: systemPrompt:",
        //   systemPrompt,
        //   "ComType:",
        //   comType,
        //   "CurrentActivityType:",
        //   currentActivityType,
        //   "chatTurnCounter:",
        //   chatTurnCounter,
        //   "initialSystemQuestion:",
        //   initialSystemQuestion
        // );
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        audioContext.current = new (window.AudioContext ||
          window.webkitAudioContext)();
        mediaRecorder.current = new MediaRecorder(stream);
        /*****************************
         * Step1: Start Audio Recording:
         * When MediaRecorder has started
         * Everytime data is available you will
         * add it to your chunksarray
         *****************************/

        mediaRecorder.current.ondataavailable = (event) => {
          /************************************************************
           * IMPORTANT: MEDIA_RECORDER EXIT CONDITION:
           * In order to stop processing jump data from a
           * previous scenario, we must delete the previous
           * recording audio chunks, when the next button is clicked.
           * (1) Identify next button is clicked
           * (2) Delete previous audio chunks
           *************************************************************/
          // (1) Identify next button click
          // console.log("Next button was clicked! submitBtnClicked:1",
          //   nextBtnClicked.current
          // );
          if (nextBtnClicked.current) {
            // console.log(
            //   "Next button was clicked! submitBtnClicked:1",
            //   nextBtnClicked.current
            // );
            wordDetectedRef.current = null;
            setChatHistory([]);
            setAudioHistory([]);

            // (2) Delete previous audio chunks
            audioChunks.current = []; // Clear audio chunks
            reset();
            return; // Exit if next button is clicked
          }
          /***************************************************************/
          if (event.data.size > 0) {
            audioChunks.current.push(event.data);
          }
        };
        /*****************************************************************
         * Step 5: Create the arguments analyser, isRecording, dataArray, to be used by
         * the useCheckSilence hook, to filter out silence and trim audio
         *****************************************************************/
        const audioInput = audioContext.current.createMediaStreamSource(stream);
        const analyserNode = audioContext.current.createAnalyser();
        analyserNode.fftSize = 2048; // Ensure this is set
        audioInput.connect(analyserNode);

        setAnalyser(analyserNode);
        const newDataArray = new Uint8Array(analyserNode.fftSize);
        setDataArray(newDataArray);
        // console.log("Recording starting");

        mediaRecorder.current.start();
        // Declare a variable to hold the analysis promise
        console.log("Recording started");

        // Start analyzing audio if nextBtn is not clicked
        if (!nextBtnClicked.current) {

          /***********************************
           * This is required to get it working
           ************************************/

          wordDetectedRef.current = true;
          console.log("NextButtonClicked:", nextBtnClicked.current, "isRecording:", isRecording, "Is speaking detected:", wordDetectedRef.current);
          setWordDetected(true);
          /******************************/
        }

        /*************************************************
         * Step 2: Stop Audio Recording: Create audioBlob
         * When MediaRecorder has been stopped
         * trim Audio Sample and send for processing
         **************************************************/
        mediaRecorder.current.onstop = async () => {
          if (nextBtnClicked.current) {
            wordDetectedRef.current = null;
            return;
          }

          const audioBlob = new Blob(audioChunks.current, {
            type: "audio/wav",
          });
          // Reset audioChunks after audioBlob is created
          audioChunks.current = [];

          try {
            // First check if we have audio and initial wordDetected was true
            if (audioBlob.size > 0) {
              // Wait for language detection to complete before doing anything else
              const hasLanguage = await analyzeChatStreamWithLangDetection(audioBlob);
              console.log("Language detection result:", hasLanguage);

              // Update refs and state with result
              wordDetectedRef.current = hasLanguage;
              setWordDetected(hasLanguage);
              console.log("isSilentRef.current:", isSilentRef.current, "wordDetectedRef.current:", wordDetectedRef.current, "hasLanguage:", hasLanguage, type);

              // Only proceed with audio processing if language was detected
              // 01/16/2025:if (hasLanguage && !isSilentRef.current) {
              if (!isSilentRef.current) {
                let dataObject = null;

                if (chatInteraction) {
                  dataObject = await processChatAudio(
                    isSystemAudioResponseRequested,
                    initialSystemQuestion,
                    systemPrompt,
                    chatTurnCounter,
                    audioBlob,
                    chatHistory,
                    setChatHistory,
                    false,
                    false,
                    audioHistory,
                    setAudioHistory,
                    wordDetectedRef.current,
                    initialQuestionAskedRef.current,
                    nextBtnClicked,
                    wordDetectedRef,
                    userAudioBlobs,
                    setUserAudioBlobs,
                  );
                } else if (currentActivityType === "passage") {
                  dataObject = await processPassageAudio(
                    audioBlob,
                    chatHistory,
                    setChatHistory,
                    false,
                    false,
                    wordDetectedRef.current,
                    currentActivityType
                  );
                } else if (currentActivityType === "advice") {
                  dataObject = await processPassageAudio(
                    audioBlob,
                    chatHistory,
                    setChatHistory,
                    false,
                    false,
                    wordDetectedRef.current,
                    currentActivityType
                  );
                }
                else {
                  dataObject = await processAudio(
                    audioBlob,
                    chatHistory,
                    setChatHistory,
                    false,
                    false,
                    wordDetectedRef.current
                  );
                }

                if (currentActivityType === "passage" || !chatInteraction) {
                  setPassageData(dataObject);
                }
              }
            }
          } catch (error) {
            console.error("Error in audio processing:", error);
            wordDetectedRef.current = false;
            setWordDetected(false);
          }
        };
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    };
    //end startRecording

    /**
     * Step0 of 5: Stop microphone and checkSilence Looper
     */
    const stopRecording = async () => {
      try {
        // Stop the MediaRecorder
        if (mediaRecorder.current && mediaRecorder.current.state !== "inactive") {
          mediaRecorder.current.stop();
          console.log("Recording stopped.");

          // Optionally process the audio data
          const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
          audioChunks.current = []; // Clear the chunks for future recordings
          // console.log("Recording stopped and audio processed.");
        } else {
          // console.log("MediaRecorder is not active or already stopped.");
        }
      } catch (error) {
        console.error("Error stopping recording:", error);
      }
    };

    useEffect(() => {
      // setWordDetected(wordDetectedRef.current);
      console.log(
        "Should come after wordDetected is false",
        "wordDetectedRef:",
        wordDetectedRef,
        "isRecording:",
        isRecording,
        "wordDetected:", wordDetected
      );
      if (wordDetectedRef.current === false) {
        console.log("wordDetectedRef-False:", wordDetectedRef);
        // setIsRecording(false);
        // Reset AudioChunks
        // audioChunks.current = []
        // if(isRecording) {
        //   // Not sure how to kick off processAudio with wordDetectedRef false
        //   // setIsRecording(true)
        // }
      }
      else {
        console.log("wordDetectedRef-True:", wordDetectedRef);
      }
    }, [wordDetected]);


    /**
     * COMBINE SYSTEM AUDIO AND USER AUDIO
     */
    useEffect(() => {
      if (systemAudioBlobs.length > 0 || userAudioBlobs.length > 0) {
        let newCombinedArray = [];

        // 1. Ensure the first item is from systemAudioBlobs
        if (systemAudioBlobs.length > 0) {
          newCombinedArray = [systemAudioBlobs[0]]; // Add the first systemAudioBlob as the first item
        }

        // 2. Keep track of the index for both arrays
        let systemIndex = 1; // Start from the second element of systemAudioBlobs
        let userIndex = 0;   // Start from the first element of userAudioBlobs

        // 3. Add remaining elements alternately between userAudioBlobs and systemAudioBlobs
        while (userIndex < userAudioBlobs.length || systemIndex < systemAudioBlobs.length) {
          // Add from userAudioBlobs if available
          if (userIndex < userAudioBlobs.length) {
            newCombinedArray.push(userAudioBlobs[userIndex]);
            userIndex++; // Move to the next userAudioBlob
          }

          // Add from systemAudioBlobs if available
          if (systemIndex < systemAudioBlobs.length) {
            newCombinedArray.push(systemAudioBlobs[systemIndex]);
            systemIndex++; // Move to the next systemAudioBlob
          }
        }

        // 4. Update the combinedBlobs state with the new array
        setCombinedBlobs(newCombinedArray);
      }
    }, [systemAudioBlobs, userAudioBlobs]); // Run this effect whenever systemAudioBlobs or userAudioBlobs changes


    useEffect(() => {
      // console.log("CombinedBlobs:", combinedBlobs);

      const combineAudioArray = async () => {
        if (combinedBlobs.length > 0) {
          // if (combinedBlobs.length >= 3) {
          // Test that audio plays
          const combinedAudioBlob = await combineAudioBlobs(combinedBlobs);
          // console.log("CombinedAudioBlob:", combinedAudioBlob)

          // playAudioBlob(combinedAudioBlob);
          setSystemAndUserAudio(combinedAudioBlob)
        }
      }// handleAudioHistory

      combineAudioArray();
    }, [combinedBlobs]);


    return (
      <WrappedComponent
        {...props}
        isRecording={isRecording}
        setIsRecording={setIsRecording}
        passageData={passageData}
        activityCountdownTimerComplete={activityCountdownTimerComplete}
        setActivityCountdownTimerComplete={setActivityCountdownTimerComplete}
        isSilentRef={isSilentRef}
        isSilentForFiveSeconds={isSilentForFiveSeconds}
        silentAfterTalking4AtLeast5Seconds={silentAfterTalking4AtLeast5Seconds}
        isTalkingForFiveSeconds={isTalkingForFiveSeconds}
        startedTalking={startedTalking}
        toggle={toggle}
        type={type}
        startRecording={startRecording}
        // startSystemRecording={startSystemRecording}
        // systemMediaRecorder={systemMediaRecorder}
        // systemAudioBlobRef={systemAudioBlobRef}
        stopRecording={stopRecording}
        // stopSystemRecording={stopSystemRecording}
        setPassageData={setPassageData}
        chatHistory={chatHistory}
        setChatHistory={setChatHistory}
        audioHistory={audioHistory}
        setAudioHistory={setAudioHistory}
        combinedBlobs={combinedBlobs}
        setCombinedBlobs={setCombinedBlobs}
        systemAndUserAudio={systemAndUserAudio}
        setSystemAndUserAudio={setSystemAndUserAudio}
        textRef={textRef}
        systemAudioBlobs={systemAudioBlobs}
        setSystemAudioBlobs={setSystemAudioBlobs}
        setUserAudioBlobs={setUserAudioBlobs}
        processText={processText}
        processAudio={processAudio}
        playAudio={playAudio}
        playSystemAudio={playSystemAudio}
        wordDetectedRef={wordDetectedRef}
        wordDetected={wordDetected}
        rms={rms}
        reset={reset}
        stopSystemAudioPlayback={stopSystemAudioPlayback}
        initialQuestionAskedRef={initialQuestionAskedRef}
      />
    );
  };
};

export default withRecording;
