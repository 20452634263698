import { transcribeAudio } from "../OpenAi"
import langid from 'langid';
import {VoiceDetector} from "./VoiceDetector";


// Improved audio chunk processing with better voice detection
export const processChatAudioChunk = async (audioBlob) => {
  if (!audioBlob || audioBlob.size === 0) {
    console.error('Invalid audio blob received');
    return false;
  }

  try {
    // Initialize voice detector with more sensitive settings
      const detector = new VoiceDetector({
      energyThreshold: -50, // requires louder sound
      silenceThreshold: -65, // for noisy environments
      minSpeechFrames: 5 // less miscalculations on transient sounds as speech
      // energyThreshold: -55,  // More sensitive energy threshold
      // silenceThreshold: -70, // for silent environments)
      // minSpeechFrames: 3     // quicker speech detection
    });

    // Convert blob to ArrayBuffer for analysis
    const arrayBuffer = await audioBlob.arrayBuffer();
    const voiceResult = await detector.processAudioData(arrayBuffer);

    // Log detailed voice detection results
    console.log('Voice detection results:', {
      containsSpeech: voiceResult.containsSpeech,
      averageEnergy: voiceResult.averageEnergy,
      speechFrames: voiceResult.speechFrames
    });

    if (!voiceResult.containsSpeech) {
      return false;
    }

    // Proceed with transcription only if voice is detected
    const transcribedText = await transcribeAudio(audioBlob);
    if (!transcribedText || transcribedText.trim().length === 0) {
      return false;
    }

    // Improved text cleaning and analysis
    const cleanedText = transcribedText
      .toLowerCase()
      .replace(/[^\w\s]|_/g, '')
      .trim();

    // More robust language detection
    const tokens = cleanedText.split(/\s+/).filter(token => token.length > 1);
    if (tokens.length === 0) return false;

    const englishTokens = tokens.filter(token => {
      const detections = langid.detect(token);
      return detections.some(([lang, confidence]) => 
        lang === "english" && confidence >= 0.2
      );
    });

    // Require at least 20% of tokens to be English
    return englishTokens.length >= Math.max(1, tokens.length * 0.2);

  } catch (error) {
    console.error('Error processing audio:', error);
    return false;
  }
}; 