import {processChatAudioChunk} from "./processChatAudioChunk";
import { transcribeAudio } from "../OpenAi"

// Improved audio stream analysis with better chunk handling and error detection
export const analyzeChatStreamWithLangDetection = async (audioBlob) => {
  if (!audioBlob || audioBlob.size === 0) {
    console.log("Invalid or empty audio blob", { size: audioBlob?.size });
    return false;
  }

  try {
    console.log("Processing audio blob:", { 
      size: audioBlob.size, 
      type: audioBlob.type 
    });

    // Process audioBlob:
    const transcribedText = await transcribeAudio(audioBlob); // Replace with your transcription function
    console.log("Old_Transcribed Text:", transcribedText);
        if (!transcribedText) {
          console.error("Old_Transcription failed.");
          return false;
        }

    // Process the audio blob directly
    const result = await processChatAudioChunk(audioBlob);
    console.log("Language detection result:", result);
    
    return result;

  } catch (error) {
    console.error("Error in analyzeStreamWithLangDetection:", error);
    return false;
  }
};
 
  
// export const analyzeStreamWithLangDetection = (stream, audioChunksRef) => {
//     console.log("In analyzeStreamWithLangDetection");
//     console.log("audioChunksRef:", audioChunksRef, "Length:", audioChunksRef.current.length);
//     return new Promise((resolve) => {
//       const audioContext = new AudioContext();
//       const mediaStreamSource = audioContext.createMediaStreamSource(stream);
//       const analyser = audioContext.createAnalyser();
//       analyser.fftSize = 2048;
//       mediaStreamSource.connect(analyser);
  
//       // Interval-based analysis
//       const interval = setInterval(async () => {
//         if (audioChunksRef.current.length > 0) {
//           // Create an audioBlob from the accumulated audio chunks
//           const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
//           audioChunksRef.current = []; // Clear processed chunks after creating the blob
  
//           // Pass the audioBlob to processAudioChunk
//           const isEnglishDetected = await processAudioChunk(audioBlob);
  
//           if (isEnglishDetected) {
//             clearInterval(interval); // Stop interval
//             resolve(true); // Resolve with true if English is detected
//           }
//         }
//       }, 1000); // Process every 1 second
  
//       // Handle stream end
//       stream.oninactive = () => {
//         clearInterval(interval);
//         resolve(false); // Resolve with false if the stream ends without detecting English
//       };
//     });
//   };
  
  