export class VoiceDetector {
    constructor(options = {}) {
      this.options = {
        // Energy threshold - determines minimum volume level to consider as speech
        // Lowered from -50 to -55 to detect softer speech
        energyThreshold: options.energyThreshold || -55,
        
        // Silence threshold - level below which is considered silence
        // Lowered from -65 to -70 to better distinguish quiet speech from silence
        silenceThreshold: options.silenceThreshold || -70,
        
        // Minimum frames that must contain speech to trigger detection
        // Reduced from 5 to 3 for faster detection
        minSpeechFrames: options.minSpeechFrames || 3,
        
        // New: Maximum silence duration (in frames) before stopping detection
        maxSilenceFrames: options.maxSilenceFrames || 10,
        
        // New: Frame size in samples (assuming 44.1kHz sample rate)
        frameSize: options.frameSize || 2048,
        
        // New: Overlap between frames (50% overlap)
        frameOverlap: options.frameOverlap || 0.5
      };
  
      this.speechFrameCount = 0;
      this.silenceFrameCount = 0;
    }
  
    async processAudioData(arrayBuffer) {
      const audioData = await this.convertToAudioData(arrayBuffer);
      const frames = this.splitIntoFrames(audioData);
      
      let totalEnergy = 0;
      let speechFrames = 0;
      
      for (const frame of frames) {
        const energy = this.calculateEnergy(frame);
        totalEnergy += energy;
        
        if (energy > this.options.energyThreshold) {
          this.speechFrameCount++;
          this.silenceFrameCount = 0;
          speechFrames++;
        } else if (energy < this.options.silenceThreshold) {
          this.silenceFrameCount++;
          this.speechFrameCount = Math.max(0, this.speechFrameCount - 1);
        }
        
        // Early detection if we have enough speech frames
        if (this.speechFrameCount >= this.options.minSpeechFrames) {
          return {
            containsSpeech: true,
            averageEnergy: totalEnergy / frames.length,
            speechFrames: speechFrames
          };
        }
        
        // Early termination if too much silence
        if (this.silenceFrameCount >= this.options.maxSilenceFrames) {
          break;
        }
      }
      
      return {
        containsSpeech: this.speechFrameCount >= this.options.minSpeechFrames,
        averageEnergy: totalEnergy / frames.length,
        speechFrames: speechFrames
      };
    }
  
    // Helper methods...
    calculateEnergy(frame) {
      return frame.reduce((sum, sample) => sum + (sample * sample), 0) / frame.length;
    }
  
    splitIntoFrames(audioData) {
      const frames = [];
      const stepSize = Math.floor(this.options.frameSize * (1 - this.options.frameOverlap));
      
      for (let i = 0; i < audioData.length - this.options.frameSize; i += stepSize) {
        frames.push(audioData.slice(i, i + this.options.frameSize));
      }
      
      return frames;
    }
  
    async convertToAudioData(arrayBuffer) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      return audioBuffer.getChannelData(0);
    }
  }