import React, { useState, useRef, useEffect } from "react";
import {
  processPassageAudio,
  processAudio,
  processText,
  playAudio,
  stopSystemAudioPlayback,
  processChatAudio,
  processAdviceAudio,
} from "../../../../utils/OpenAi";
import useCheckSilence from "../../../../utils/hooks/useCheckSilence";
// import { analyzeRealTimeAudioWithWebSpeechAPI } from "../../../../utils/analyzeRealTimeAudioWithWebSpeechAPI";
import { analyzeStreamWithLangDetection } from "../../../../utils/languageDetection/analyzeStreamWithLangDetection";

/**
 * Higher-Order Component (HOC) to provide recording functionality to a wrapped component.
 * @param {React.Component} WrappedComponent - The component to wrap with recording functionality.
 * @returns {React.Component} - The wrapped component with added recording functionality.
 */
const withRecording = (WrappedComponent) => {
  return (props) => {
    const wordDetectedRef = useRef(null);

    const [isRecording, setIsRecording] = useState(false);
    const [dataArray, setDataArray] = useState(null);
    const [analyser, setAnalyser] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [audioHistory, setAudioHistory] = useState([]);
    const [passageData, setPassageData] = useState(null);
    const [activityCountdownTimerComplete, setActivityCountdownTimerComplete] =
      useState(false);
    const [wordDetected, setWordDetected] = useState(wordDetectedRef.current);

    const {
      silentAfterTalking4AtLeast5Seconds,
      isSilentForFiveSeconds,
      isTalkingForFiveSeconds,
      startedTalking,
      toggle,
      type,
      rms
    } = useCheckSilence(
      analyser,
      isRecording,
      dataArray,
      activityCountdownTimerComplete
    );
    const audioChunks = useRef([]);
    const mediaRecorder = useRef(null);
    const audioContext = useRef(null);
    const animationFrameId = useRef(null);
    const isSilentRef = useRef(isSilentForFiveSeconds);

    /**
     * Setup microphone for recording
     * and calls process Audio
     * @returns {Promise<void>}
     */
    const startRecording = async (
      isSystemAudioResponseRequested,
      initialSystemQuestion,
      comType,
      systemPrompt,
      currentActivityType,
      chatTurnCounter
    ) => {
      const chatInteraction =
        comType === "spoken" &&
        systemPrompt &&
        currentActivityType === "chat" &&
        typeof chatTurnCounter === "number" &&
        typeof initialSystemQuestion === "string";

      console.log("chatInteraction:", chatInteraction);

      if (chatInteraction) {
        console.log(
          "STARTING RECORDING: systemPrompt:",
          systemPrompt,
          "ComType:",
          comType,
          "CurrentActivityType:",
          currentActivityType,
          "chatTurnCounter:",
          chatTurnCounter,
          "initialSystemQuestion:",
          initialSystemQuestion
        );
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        audioContext.current = new (window.AudioContext ||
          window.webkitAudioContext)();
        mediaRecorder.current = new MediaRecorder(stream);

        /*****************************
         * Step1: Start Audio Recording:
         * When MediaRecorder has started
         * Everytime data is available you will
         * add it to your chunksarray
         *****************************/
        mediaRecorder.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunks.current.push(event.data);
          }
        };

        /*****************************************************************
         * Step 5: Create the arguments analyser, isRecording, dataArray, to be used by
         * the useCheckSilence hook, to filter out silence and trim audio
         *****************************************************************/
        const audioInput = audioContext.current.createMediaStreamSource(stream);
        const analyserNode = audioContext.current.createAnalyser();
        analyserNode.fftSize = 2048; // Ensure this is set
        audioInput.connect(analyserNode);

        // Setup Analyser State
        setAnalyser(analyserNode);

        const newDataArray = new Uint8Array(analyserNode.fftSize);
        // Setup dataArray State
        setDataArray(newDataArray);

        // launchStart
        mediaRecorder.current.start();

        console.log("Recording started");
        // Analyze real-time audio
        analyzeStreamWithLangDetection(stream)
          .then((result) => {
            console.log("analyzeRealTimeAudioWithWebSpeechAPI Result:", result)
            wordDetectedRef.current = result;
            console.log("Is speaking detected:", wordDetectedRef.current);
            console.log("Setting wordDetected Result:", result);
            setWordDetected(result);
          })
          .catch((error) => {
            console.error("Error during speech analysis:", error);
          });

        /*************************************************
         * Step2: Stop Audio Recording: Create audioBlob
         * When MediaRecorder has been stopped
         * trim Audio Sample and send for processing
         **************************************************/
        mediaRecorder.current.onstop = async () => {
          // Save all audio as an audio file when recording stopped
          const audioBlob = new Blob(audioChunks.current, {
            type: "audio/wav",
          });
          console.log("audioChunks:", audioChunks);

          // Step 3: clear the chunks array, so next time we record it is empty
          audioChunks.current = [];

          /************************
           * Play AudioBlob
           ************************/
          // playAudioBlob(audioBlob);

          /*************************************
           * Step4: Process audio when noise is detected and transcribe data
           * ************************************/
          console.log(
            "isSilentForFiveSeconds:",
            isSilentForFiveSeconds,
            "@isSilentRef.current:",
            isSilentRef.current,
            "activityCountdownTimerComplete:",
            activityCountdownTimerComplete
          );
          let dataObject = null;
          /********************************
           * IMPORTANT: Checks if audio is a word
           * in Chat activityType, but not in Advice
           * activityType
           *******************************/
          let soundDetected = !isSilentRef.current;
          console.log(
            "soundDetected:",
            soundDetected,
            "audioBlob.size",
            audioBlob.size,
            "audioBlob:",
            audioBlob,
            audioBlob.size > 0
          );

          if (soundDetected && audioBlob.size > 0) {
            console.log(
              "&& activityCountdownTimerComplete:",
              activityCountdownTimerComplete,
              "soundDetected:",
              soundDetected,
              "audioBlob.size",
              audioBlob.size
            );

            /***************************************
             * PROCESSAUDIO:  If audio has been
             * collected then send to the processAudio
             * to trim and remove silent portions after
             * the "I'm done" button clicked or page
             * transitioned automatically.
             ***************************************/
            const requestSystemResponse = false;
            const requestSystemTranscription = false;

            if (chatInteraction) {
              console.log("Processing Audio");
              dataObject = await processChatAudio(
                isSystemAudioResponseRequested,
                initialSystemQuestion,
                systemPrompt,
                chatTurnCounter,
                audioBlob,
                chatHistory,
                setChatHistory,
                requestSystemResponse,
                requestSystemTranscription,
                audioHistory,
                setAudioHistory,
                wordDetectedRef.current
              );
            } else if (currentActivityType === "passage") {
              dataObject = await processPassageAudio(
                audioBlob,
                chatHistory,
                setChatHistory,
                requestSystemResponse,
                requestSystemTranscription,
                wordDetectedRef.current,
                currentActivityType
              );
            } else if (currentActivityType === "advice") {
              dataObject = await processPassageAudio(
                audioBlob,
                chatHistory,
                setChatHistory,
                requestSystemResponse,
                requestSystemTranscription,
                wordDetectedRef.current,
                currentActivityType
              );
            }
            else {
              dataObject = await processAudio(
                audioBlob,
                chatHistory,
                setChatHistory,
                requestSystemResponse,
                requestSystemTranscription,
                wordDetectedRef.current
              );
            }
            console.log("dataObject:", dataObject, "chatHistory:", chatHistory);
            /**************************************
             * IMPORTANT: Passage Activity Type uses this
             * to Trigger the next page after nextBtn
             * Clicked
             **************************************/
            if (currentActivityType === "passage" || !chatInteraction) {
              setPassageData(dataObject);
            }
          }
        }; //onstop
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    }; //end startRecording

    /**
     * Step0 of 5: Stop microphone and checkSilence Looper
     */
    const stopRecording = () => {
      // if(wordDetectedRef.current) {
      console.log("In Stop Recording");
      // Stop microphone
      if (mediaRecorder.current && mediaRecorder.current.state !== "inactive") {
        mediaRecorder.current.stop();
      }
      // Stop checkSilence Looper
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      audioChunks.current = []; // Clear any remaining audio chunks
      // } // wordDetectedRef.current
    };

    useEffect(() => {
      console.log("Should come after wordDetected is false", "wordDetectedRef:", wordDetectedRef, "isRecording:", isRecording)
      if ((wordDetectedRef.current === false)) {
        console.log("wordDetectedRef:", wordDetectedRef)
        // setIsRecording(false);
      }
    }, [wordDetected]);

    return (
      <WrappedComponent
        {...props}
        isRecording={isRecording}
        setIsRecording={setIsRecording}
        passageData={passageData}
        activityCountdownTimerComplete={activityCountdownTimerComplete}
        setActivityCountdownTimerComplete={setActivityCountdownTimerComplete}
        isSilentRef={isSilentRef}
        isSilentForFiveSeconds={isSilentForFiveSeconds}
        silentAfterTalking4AtLeast5Seconds={silentAfterTalking4AtLeast5Seconds}
        isTalkingForFiveSeconds={isTalkingForFiveSeconds}
        startedTalking={startedTalking}
        toggle={toggle}
        type={type}
        startRecording={startRecording}
        stopRecording={stopRecording}
        setPassageData={setPassageData}
        chatHistory={chatHistory}
        setChatHistory={setChatHistory}
        audioHistory={audioHistory}
        setAudioHistory={setAudioHistory}
        processText={processText}
        processAudio={processAudio}
        playAudio={playAudio}
        wordDetectedRef={wordDetectedRef}
        wordDetected={wordDetected}
        rms={rms}
        stopSystemAudioPlayback={stopSystemAudioPlayback}
      />
    );
  };
};

export default withRecording;
